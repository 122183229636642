/*============================================================================
  Minimal | A theme by Shopify
  Built on Timber v2.0.0
==============================================================================*/
/*================ Variables, theme settings, and Sass mixins from Timber ================*/
/*================ Global | Sass Mixins ================*/
/*============================================================================
  Prefix mixin for generating vendor prefixes.
  Based on https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/addons/_prefixer.scss
  Usage:
    // Input:
    .element {
      @include prefix(transform, scale(1), ms webkit spec);
    }
    // Output:
    .element {
      -ms-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1);
    }
==============================================================================*/
/*============================================================================
  Dependency-free breakpoint mixin
    - http://blog.grayghostvisuals.com/sass/sass-media-query-mixin/
==============================================================================*/
/*============================================================================
  Accent text
==============================================================================*/
/*============================================================================
  Flexbox prefix mixins from Bourbon
    https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/css3/_flex-box.scss
==============================================================================*/
/*================ The following are dependencies of csswizardry grid ================*/
/*================ Color variables ================*/
/*================ Typography variables ================*/
@font-face {
  font-family: "PT Sans";
  font-weight: 700;
  font-style: normal;
  src: url("https://fonts.shopifycdn.com/pt_sans/ptsans_n7.c749a0b064a97f5c7a1df8f0a6e5570abc392dc4.woff2?&hmac=67b22e7e2ff4212e98a5f191e9946e6e8ab2648a9df4a62888a3a7b611088ba2") format("woff2"), url("https://fonts.shopifycdn.com/pt_sans/ptsans_n7.fb6210b08a554af4e25bd2ba9f9e194fdaaef01d.woff?&hmac=c55b7306f04eb046764447c80cc3b7fc06fc2b897bfcfe06f02dd2091ccc161a") format("woff"); }

@font-face {
  font-family: Helvetica;
  font-weight: 400;
  font-style: normal;
  src: url("https://fonts.shopifycdn.com/helvetica/helvetica_n4.fe093fe9ca22a15354813c912484945a36b79146.woff2?&hmac=64c57d7fee8da8223a0d4856285068c02c248ef210cae57dcd9c3e633375e8a4") format("woff2"), url("https://fonts.shopifycdn.com/helvetica/helvetica_n4.8bddb85c18a0094c427a9bf65dee963ad88de4e8.woff?&hmac=f74109e3105603c8a8cfbd8dec4e8a7e53572346fb96aacec203fc3881ddabf1") format("woff"); }

@font-face {
  font-family: "Crimson Text";
  font-weight: 700;
  font-style: normal;
  src: url("https://fonts.shopifycdn.com/crimson_text/crimsontext_n7.616fc345f06a30c684b31457d4f245d48537bb3e.woff2?&hmac=b73065956a4cfb8a620e62f4e8c1a623792bfa9eed023c6988d3f2c3d437e927") format("woff2"), url("https://fonts.shopifycdn.com/crimson_text/crimsontext_n7.6b04e03a76dba315613cc09b61361b2c3223caf3.woff?&hmac=70ce6209f801fb4c8062695f2bfce1cb469f8d08d8e7839f329e0f3c2ffae6b2") format("woff"); }

@font-face {
  font-family: Helvetica;
  font-weight: 700;
  font-style: normal;
  src: url("https://fonts.shopifycdn.com/helvetica/helvetica_n7.39bee04bd277a9c4e94e2fd42d53f4e3c0afb8a5.woff2?&hmac=b99c35ba03edd5495ba7af87326d5fe8af5b2363f485475338ae16401b9af50d") format("woff2"), url("https://fonts.shopifycdn.com/helvetica/helvetica_n7.b95359461a6788d5549e702cb2123bc40c227e49.woff?&hmac=684bc7a600f408db705c8f9b90d3d964d22c13a8ce7492386d351a852f30309b") format("woff"); }

@font-face {
  font-family: Helvetica;
  font-weight: 400;
  font-style: oblique;
  src: url("https://fonts.shopifycdn.com/helvetica/helvetica_o4.f9832a0fc1ee8fc5a359636e410d6941e1e4ca03.woff2?&hmac=775a8a34e13ea386c331d51fd5f061993cd3135ce54cc4ed2b9254f3a3be05df") format("woff2"), url("https://fonts.shopifycdn.com/helvetica/helvetica_o4.0063286aec443697ebae04eeb9c78b820f9f7805.woff?&hmac=32c2cf4f29cdfb274d76aeb45a9a8b5c97eb6a9255cc2073ee5c1a85140ad7fe") format("woff"); }

@font-face {
  font-family: Helvetica;
  font-weight: 700;
  font-style: oblique;
  src: url("https://fonts.shopifycdn.com/helvetica/helvetica_o7.215fd4ca1a06214fa01e44328a1992c977700ea2.woff2?&hmac=b7d9523eaf6b1e57f158595303d5e41e66c07d43212d2fbfe8df1f4b7d6c1f79") format("woff2"), url("https://fonts.shopifycdn.com/helvetica/helvetica_o7.b0a2026ae2aa64d060f9ce0ee1b3c3c1aa21bf88.woff?&hmac=97dc9f03549c58cc74feee46ffde68daa822080823ae1159e9cd489daf68bcb9") format("woff"); }

.placeholder-svg, .icon--placeholder {
  display: block;
  fill: rgba(27, 30, 47, 0.35);
  background-color: rgba(27, 30, 47, 0.05);
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  border: 1px solid rgba(27, 30, 47, 0.15); }

.placeholder-noblocks {
  padding: 40px;
  text-align: center; }

.placeholder-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .placeholder-background .icon {
    border: 0; }

.image-bar__content .placeholder-image {
  position: absolute;
  top: 0;
  left: 0; }

.grid-link__image-centered .placeholder-svg {
  height: initial;
  max-height: initial; }

/*================ Vendor-specific styles ================*/
/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #faf9f7;
  opacity: 1;
  filter: alpha(opacity=100); }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }

.mfp-preloader a {
  color: #CCC; }

.mfp-preloader a:hover {
  color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  filter: alpha(opacity=65);
  padding: 0 0 18px 10px;
  color: #1b1e2f;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }

.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
  filter: alpha(opacity=100); }

.mfp-close:active {
  top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  filter: alpha(opacity=65);
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.mfp-arrow:active {
  margin-top: -54px; }

.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
  filter: alpha(opacity=100); }

.mfp-arrow:before, .mfp-arrow:after, .mfp-arrow .mfp-b, .mfp-arrow .mfp-a {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }

.mfp-arrow:after, .mfp-arrow .mfp-a {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }

.mfp-arrow:before, .mfp-arrow .mfp-b {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }

.mfp-arrow-left:after, .mfp-arrow-left .mfp-a {
  border-right: 17px solid #FFF;
  margin-left: 31px; }

.mfp-arrow-left:before, .mfp-arrow-left .mfp-b {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow-right:after, .mfp-arrow-right .mfp-a {
  border-left: 17px solid #FFF;
  margin-left: 39px; }

.mfp-arrow-right:before, .mfp-arrow-right .mfp-b {
  border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px; }

.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444; }

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px; }

.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }

  .mfp-img-mobile img.mfp-img {
    padding: 0; }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }
.mfp-ie7 .mfp-img {
  padding: 0; }

.mfp-ie7 .mfp-bottom-bar {
  width: 600px;
  left: 50%;
  margin-left: -300px;
  margin-top: 5px;
  padding-bottom: 5px; }

.mfp-ie7 .mfp-container {
  padding: 0; }

.mfp-ie7 .mfp-content {
  padding-top: 44px; }

.mfp-ie7 .mfp-close {
  top: 0;
  right: 0;
  padding-top: 0; }

/*================ Theme-specific partials ================*/
h1, .h1 {
  font-size: 1.875em;
  line-height: 1.2; }

h2, .h2 {
  font-size: 1.625em;
  line-height: 1.3; }

h3, .h3 {
  font-size: 1.3125em;
  line-height: 1.4; }

h4, .h4,
.tags {
  font-size: 0.9375em;
  line-height: 1.6; }

h4, .h4 {
  font-size: 0.9375em;
  font-weight: 700; }

h5, .h5 {
  font-size: 0.9375em;
  line-height: 1.6; }

h6, .h6 {
  font-size: 0.8125em;
  line-height: 1.7; }

/*================ Footer ================*/
.site-footer p,
.site-footer li,
.site-footer .rte,
.site-footer input {
  font-size: 0.85em; }

.main-content {
  margin-top: 15px; }
  .template-index .main-content {
    margin-top: 0; }

html, body {
  background: #faf9f7; }

/*================ Index sections ================*/
.index-section {
  padding-top: 17.5px;
  padding-bottom: 17.5px; }
  @media screen and (min-width: 769px) {
    .index-section {
      padding-top: 27.5px;
      padding-bottom: 27.5px; } }
  .shopify-section:first-child .index-section {
    padding-top: 0;
    border-top: 0; }
  .shopify-section:last-child .index-section {
    padding-bottom: 0; }

/*================ Module-specific styles ================*/
.header-bar {
  *zoom: 1;
  font-family: "PT Sans", sans-serif;
  font-size: 0.875em;
  font-weight: 700;
  font-style: normal;
  background-color: #ffb600;
  color: white;
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center; }
  .header-bar:after {
    content: "";
    display: table;
    clear: both; }
  @media screen and (min-width: 769px) {
    .header-bar {
      text-align: right;
      padding-top: 8px;
      padding-bottom: 8px; } }
  .header-bar a,
  .header-bar button {
    color: white; }
    .header-bar a:hover, .header-bar a:active, .header-bar a:focus,
    .header-bar button:hover,
    .header-bar button:active,
    .header-bar button:focus {
      outline-color: white; }
  .header-bar .inline-list {
    margin-bottom: 0; }
    .header-bar .inline-list li {
      margin-bottom: 0; }

@media screen and (min-width: 769px) {
  .header-bar__left {
    text-align: left;
    width: 33.33%; }

  .header-bar__right {
    width: 66.66%; } }
.header-bar__module {
  margin-bottom: 15px; }
  .header-bar__right .header-bar__module:last-child {
    margin-bottom: 0; }
  @media screen and (min-width: 769px) {
    .header-bar__module {
      display: inline-block;
      vertical-align: middle;
      text-align: left;
      margin-bottom: 0; } }

.header-bar__module--list {
  list-style: none;
  margin: 0; }
  .header-bar__module--list li {
    display: inline-block;
    margin: 0; }
    .header-bar__module--list li + li {
      margin-left: 6px; }

.cart-page-link {
  display: inline-block; }

.header-bar__cart-icon {
  font-size: 1.4em;
  margin-right: 4px; }

.hidden-count {
  display: none; }

.header-bar__sep {
  display: none; }
  @media screen and (min-width: 769px) {
    .header-bar__sep {
      color: white;
      opacity: 0.4;
      display: inline-block;
      padding: 0 10px; } }

.header-bar__message, .header-message {
  max-width: 100%;
  overflow: hidden; }

.header-bar__search {
  *zoom: 1;
  position: relative;
  background-color: #fff;
  border: 0 none;
  border-radius: 2px;
  min-width: 100px; }
  .header-bar__search:after {
    content: "";
    display: table;
    clear: both; }
  @media screen and (min-width: 769px) {
    .header-bar__search {
      max-width: 160px;
      margin-left: 20px; }
      .header-bar__search:first-of-type {
        margin-left: 0; } }
  @media screen and (max-width: 768px) {
    .header-bar__search {
      margin: 12px 30px; } }
  @media screen and (max-width: 480px) {
    .header-bar__search {
      margin: 12px 15px; } }
  .header-bar__search form,
  .header-bar__search input,
  .header-bar__search button {
    margin-bottom: 0; }

.header-bar__search-input[type="search"] {
  display: block;
  width: 60%;
  float: right;
  background: transparent;
  border-color: transparent;
  padding: 5px 0; }
  .header-bar__search-input[type="search"]:focus {
    background: transparent;
    border-color: transparent; }

.header-bar__search-submit {
  position: absolute;
  display: block;
  float: left;
  width: 40%;
  font-size: 16px;
  padding: 4px 0;
  min-height: auto; }

.supports-fontface .header-bar__search-submit {
  width: 20%; }
.supports-fontface .header-bar__search-input[type="search"] {
  width: 100%;
  padding-left: 30px; }
@media screen and (max-width: 768px) {
  .supports-fontface .header-bar__search-form {
    position: relative; }
  .supports-fontface .header-bar__search-submit {
    width: 35px;
    position: absolute;
    top: 0;
    left: 0; }
  .supports-fontface .header-bar__search-input[type="search"] {
    width: 100%;
    padding-left: 35px; } }

.header-bar__search .btn,
.header-bar__search .btn:hover,
.header-bar__search .btn:focus {
  background: transparent;
  color: #555; }

.announcement-bar--mobile {
  padding-top: 5px;
  padding-bottom: 5px; }

/*================ Module | Grid Link ================*/
.grid-link__container {
  margin-bottom: -30px; }

.grid-link,
.grid-link--focus {
  position: relative;
  display: block;
  padding-bottom: 30px;
  line-height: 1.3; }
  .grid-link:hover .grid-link__image, .grid-link:active .grid-link__image,
  .grid-link--focus:hover .grid-link__image,
  .grid-link--focus:active .grid-link__image {
    opacity: 0.8; }

.grid-link--focus {
  padding: 20px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px; }
  .grid-link--focus:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffb600;
    -webkit-transition: all 0.08s ease-in;
    -moz-transition: all 0.08s ease-in;
    -ms-transition: all 0.08s ease-in;
    -o-transition: all 0.08s ease-in;
    transition: all 0.08s ease-in; }
  .grid-link--focus:hover, .grid-link--focus:active {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

.grid-link__image {
  position: relative;
  display: table;
  table-layout: fixed;
  width: 100%;
  margin: 0 auto 10px;
  -webkit-transition: opacity 0.08s ease-in;
  -moz-transition: opacity 0.08s ease-in;
  -ms-transition: opacity 0.08s ease-in;
  -o-transition: opacity 0.08s ease-in;
  transition: opacity 0.08s ease-in; }
  .grid-link__image img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 600px; }

.grid-link__image--loading {
  background-color: #1b1e2f;
  -webkit-animation: placeholder-background-loading 1.5s linear infinite;
  animation: placeholder-background-loading 1.5s linear infinite; }

.grid-link__image-centered {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  overflow: hidden; }

.sold-out .grid-link__image-sold-out img {
  opacity: 0.4;
  filter: alpha(opacity=40); }

.grid-link__title,
.grid-link__meta {
  position: relative;
  margin-bottom: 5px; }

.grid-link__title {
  color: #1b1e2f;
  font-family: "Crimson Text", serif;
  font-size: .9em;
  line-height: 1.4;
  font-weight: 700; }

.grid-link__vendor {
  font-size: .85em;
  font-weight: 400; }

.grid-link__meta {
  font-family: "Crimson Text", serif;
  font-size: .75em;
  line-height: 1.5;
  color: #2e334f; }

.grid-link__sale_price {
  opacity: 0.95;
  filter: alpha(opacity=95); }

.grid-link__unit-price {
  font-size: 1em; }

.badge {
  display: table;
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: black;
  color: white;
  border-radius: 50%;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  font-size: 0.75em;
  line-height: 1.1;
  z-index: 10; }

.badge--sold-out {
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  background-color: black;
  color: white; }

.badge--sale {
  top: -12px;
  right: -12px; }

.badge__text {
  display: table-cell;
  vertical-align: middle;
  padding: 2px 8px 0; }

.badge__text--small {
  font-size: 8px;
  padding-top: 0; }

@-webkit-keyframes placeholder-background-loading {
  0% {
    opacity: 0.02; }

  50% {
    opacity: 0.05; }

  100% {
    opacity: 0.02; } }

@-ms-keyframes placeholder-background-loading {
  0% {
    opacity: 0.02; }

  50% {
    opacity: 0.05; }

  100% {
    opacity: 0.02; } }

@keyframes placeholder-background-loading {
  0% {
    opacity: 0.02; }

  50% {
    opacity: 0.05; }

  100% {
    opacity: 0.02; } }

.mobile-nav-trigger,
.mobile-cart-page-link {
  font-weight: 700; }
  .mobile-nav-trigger .icon,
  .mobile-cart-page-link .icon {
    position: relative;
    top: -1px;
    vertical-align: middle;
    padding-right: 4px; }

.mobile-nav-trigger {
  display: block;
  float: left;
  background: none;
  border: 0 none;
  padding: 0;
  margin: 0; }
  .mobile-nav-trigger .icon {
    font-size: 1.4em; }

.mobile-cart-page-link {
  display: block;
  float: right; }
  .mobile-cart-page-link .header-bar__cart-icon {
    font-size: 1.4em; }
  .mobile-cart-page-link .cart-count:before {
    display: inline;
    content: "("; }
  .mobile-cart-page-link .cart-count:after {
    display: inline;
    content: ")"; }

.mobile-nav {
  display: none;
  list-style: none;
  text-align: left;
  margin: 0; }
  .mobile-nav li {
    margin: 0; }

.mobile-nav__link {
  display: block;
  border-top: 1px solid white;
  border-color: rgba(255, 255, 255, 0.2);
  /*================ Can't always control anchor markup to add a class ================*/ }
  .mobile-nav__link > a {
    display: block;
    padding: 12px 15px;
    font-size: 0.9375em;
    font-family: "Crimson Text", serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase; }
    @media screen and (min-width: 480px) {
      .mobile-nav__link > a {
        padding-left: 30px;
        padding-right: 30px; } }

.mobile-nav__sublist-expand,
.mobile-nav__sublist-contract {
  display: inline-block;
  font-size: 0.6em;
  vertical-align: middle;
  margin: -2px 0 0 4px; }

.mobile-nav__sublist-contract {
  display: none; }

.mobile-nav__sublist-trigger.is-active .mobile-nav__sublist-contract {
  display: inline-block; }
.mobile-nav__sublist-trigger.is-active .mobile-nav__sublist-expand {
  display: none; }

.mobile-nav__sublist {
  list-style: none;
  margin: 0;
  display: none;
  background-color: #faf9f7; }

.mobile-nav__sublist-link a {
  display: block;
  padding: 12px 15px;
  color: #1b1e2f;
  font-size: 0.9375em;
  font-family: "Crimson Text", serif;
  font-weight: 700;
  font-style: normal;
  border-top: 1px solid #faf9f7;
  padding-left: 30px;
  padding-right: 30px; }
  .mobile-nav__sublist-link a:hover {
    opacity: 1;
    color: black; }
  .mobile-nav__sublist--grandchilds .mobile-nav__sublist-link a {
    padding-left: 60px; }

.newsletter-grid {
  display: flex;
  flex-wrap: wrap; }

.newsletter-section .grid-uniform {
  margin-left: 0; }
.newsletter-section #contact_form {
  margin-bottom: 0; }
.newsletter-section .section-header__title {
  margin-bottom: 0; }
.newsletter-section .section-header__title-spacing {
  margin-bottom: 15px; }

.newsletter-wrapper .grid-uniform {
  margin-left: 0; }

.newsletter-grid__item {
  padding: 0; }

.newsletter-content-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 50px 15%; }

.newsletter-content p {
  margin: 0; }

.newsletter-section .input-group {
  display: block; }
.newsletter-section .input-group-field {
  margin-bottom: 10px; }
.newsletter-section .errors {
  margin-bottom: 10px; }

/*================ Module | Promo images ================*/
.featured-images .grid__item {
  margin-bottom: 15px; }

.collection__grid-image-wrapper {
  width: 100%;
  position: relative;
  margin: 0 auto; }

.collection__grid-image {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0; }

.custom-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  -ms-align-items: stretch;
  -o-align-items: stretch;
  align-items: stretch;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: auto;
  margin-bottom: -30px;
  margin-left: -30px; }
  @media screen and (max-width: 480px) {
    .custom-content {
      margin-bottom: -22px;
      margin-left: -22px; } }

.custom__item {
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-bottom: 30px;
  padding-left: 30px;
  max-width: 100%; }
  @media screen and (max-width: 480px) {
    .custom__item {
      -webkit-flex: 0 0 auto;
      -moz-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      padding-left: 22px;
      margin-bottom: 22px; }
      .custom__item.small--one-half {
        -webkit-flex: 1 0 50%;
        -moz-flex: 1 0 50%;
        -ms-flex: 1 0 50%;
        flex: 1 0 50%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto; } }

.custom__item-inner {
  position: relative;
  display: inline-block;
  text-align: left;
  max-width: 100%; }

.custom__item-inner--video,
.custom__item-inner--html {
  display: block; }

/*================ Flex item alignment ================*/
.align--top-middle {
  text-align: center; }

.align--top-right {
  text-align: right; }

.align--middle-left {
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center; }

.align--center {
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
  text-align: center; }

.align--middle-right {
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
  text-align: right; }

.align--bottom-left {
  -ms-flex-item-align: flex-end;
  -webkit-align-self: flex-end;
  align-self: flex-end; }

.align--bottom-middle {
  -ms-flex-item-align: flex-end;
  -webkit-align-self: flex-end;
  align-self: flex-end;
  text-align: center; }

.align--bottom-right {
  -ms-flex-item-align: flex-end;
  -webkit-align-self: flex-end;
  align-self: flex-end;
  text-align: right; }

.rich-text__heading--medium {
  font-size: 1.625em; }

.rich-text__heading--small {
  font-size: 1.3125em; }

.rich-text__text--large {
  font-size: 1.125em; }

.rich-text__text--small {
  font-size: 0.875em; }

.feature-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center; }
  @media screen and (max-width: 768px) {
    .feature-row {
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; } }

.feature-row__item {
  -webkit-flex: 0 1 50%;
  -moz-flex: 0 1 50%;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%; }
  @media screen and (max-width: 768px) {
    .feature-row__item {
      -webkit-flex: 1 1 100%;
      -moz-flex: 1 1 100%;
      -ms-flex: 1 1 100%;
      flex: 1 1 100%;
      max-width: 100%; } }

.feature-row__image-wrapper {
  position: relative;
  margin: 0 auto; }

.feature-row__image {
  display: block;
  margin: 0 auto; }
  @media screen and (max-width: 768px) {
    .feature-row__image {
      order: 1; } }
  .supports-js .feature-row__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
  .no-js .feature-row__image {
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
    position: absolute;
    height: 1px;
    width: 1px; }

.feature-row__text {
  padding-top: 35px;
  padding-bottom: 35px; }
  @media screen and (max-width: 768px) {
    .feature-row__text {
      order: 2;
      padding-bottom: 0; } }

@media screen and (min-width: 769px) {
  .feature-row__text--left {
    padding-left: 35px; }

  .feature-row__text--right {
    padding-right: 35px; } }
@media screen and (min-width: 769px) {
  .featured-row__subtext {
    font-size: 1.125em; } }
.featured-blog__post {
  margin-bottom: 30px; }
  @media screen and (max-width: 480px) {
    .featured-blog__post {
      margin-bottom: 37.5px; } }
  .featured-blog__post .article__featured-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: block; }
  .featured-blog__post .article__featured-image-wrapper {
    position: relative;
    margin-bottom: 30px; }
    @media screen and (max-width: 480px) {
      .featured-blog__post .article__featured-image-wrapper {
        margin-bottom: 22px; } }
    .no-js .featured-blog__post .article__featured-image-wrapper {
      clip: rect(0 0 0 0);
      clip: rect(0, 0, 0, 0);
      overflow: hidden;
      position: absolute;
      height: 1px;
      width: 1px; }
  .featured-blog__post .rte {
    margin-top: 22.5px; }
    @media screen and (max-width: 480px) {
      .featured-blog__post .rte {
        margin-bottom: 16.5px; } }
  .featured-blog__post .h3 {
    margin-top: -5px; }
  .featured-blog__post .featured-blog__meta {
    font-size: .85em;
    margin-bottom: -5px; }

.placeholder .article__featured-link {
  margin-bottom: 30px; }
  @media screen and (max-width: 480px) {
    .placeholder .article__featured-link {
      margin-bottom: 22px; } }

.map-section__wrapper {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.map-section__content {
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 50%;
  overflow: hidden;
  position: relative; }
  @media screen and (max-width: 768px) {
    .map-section__content {
      -webkit-flex: 0 1 100%;
      -moz-flex: 0 1 100%;
      -ms-flex: 0 1 100%;
      flex: 0 1 100%;
      max-width: 100%; } }

.map-section__image {
  height: 100%;
  background-size: cover;
  background-position: center; }
  @media screen and (max-width: 768px) {
    .map-section__image {
      order: 1; } }
  @media screen and (min-width: 769px) {
    .map-section__image {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0; } }
  .map-section--display-map .map-section__image {
    display: none !important; }

.map-section__background-wrapper {
  background-color: rgba(27, 30, 47, 0.05); }

.map-section__placeholder {
  height: 100%; }
  @media screen and (min-width: 769px) {
    .map-section__placeholder {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0; } }
  .map-section--display-map .map-section__placeholder {
    display: none !important; }

.map-section__text {
  padding: 30px;
  background-color: #f4f4f4; }
  @media screen and (max-width: 768px) {
    .map-section__text {
      order: 0; } }
  .map-section__text .icon-pin {
    height: 10.5px; }

.map-section__container {
  width: 100%;
  height: 55vh; }
  @media screen and (min-width: 769px) {
    .map-section__container {
      position: absolute !important;
      top: 0;
      left: 0;
      height: 100%; } }

@media screen and (min-width: 769px) {
  .map-section--load-error {
    width: 50%;
    margin: 0 auto; } }
.map-section--load-error .errors {
  width: 100%; }

.gallery__image-container {
  position: relative; }

.gallery__image-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%; }
.no-js .gallery__image-wrapper {
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px; }

/*================ Module | Product Lightbox ================*/
.mfp-bg {
  background-color: #faf9f7; }
  .mfp-bg.mfp-fade {
    -webkit-backface-visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
    .mfp-bg.mfp-fade.mfp-ready {
      opacity: 1;
      filter: alpha(opacity=100); }
    .mfp-bg.mfp-fade.mfp-removing {
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      opacity: 0;
      filter: alpha(opacity=0); }

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1; }
.mfp-fade.mfp-wrap.mfp-removing {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }
  .mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0; }
  .mfp-fade.mfp-wrap.mfp-removing button {
    opacity: 0; }

.mfp-counter {
  display: none; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: zoom-out; }
.mfp-figure:after {
  box-shadow: none; }

.mfp-img {
  background-color: #faf9f7; }

button.mfp-close {
  margin: 30px;
  font-size: 2.5em;
  font-weight: 300px;
  opacity: 1;
  filter: alpha(opacity=100);
  color: #1b1e2f; }

button.mfp-arrow {
  top: 0;
  height: 100%;
  width: 20%;
  margin: 0;
  opacity: 1;
  filter: alpha(opacity=100); }
  button.mfp-arrow:after, button.mfp-arrow .mfp-a {
    display: none; }
  button.mfp-arrow:before, button.mfp-arrow .mfp-b {
    display: none; }
  button.mfp-arrow:active {
    margin-top: 0; }

.mfp-chevron {
  position: absolute;
  pointer-events: none; }
  .mfp-chevron:before {
    content: '';
    display: inline-block;
    position: relative;
    vertical-align: top;
    height: 25px;
    width: 25px;
    border-style: solid;
    border-width: 4px 4px 0 0;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  .mfp-chevron.mfp-chevron-right {
    right: 55px; }
    .mfp-chevron.mfp-chevron-right:before {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg); }
  .mfp-chevron.mfp-chevron-left {
    left: 55px; }
    .mfp-chevron.mfp-chevron-left:before {
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -ms-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: rotate(-135deg); }

/*================ Variables ================*/
/*============================================================================
  #FlexSlider
    - jQuery FlexSlider v2.2.0 | http://www.woothemes.com/flexslider/
    - Contributing author: Tyler Smith (@mbmufffin)
==============================================================================*/
.slideshow-section {
  padding-bottom: 35px; }
  @media screen and (min-width: 769px) {
    .slideshow-section {
      padding-bottom: 0; } }

.flexslider {
  margin: 0;
  padding: 0;
  overflow: visible !important; }

.flexslider--adapt,
.flexslider--adapt .flex-viewport {
  max-height: 90vh; }

.flexslider--adapt.flexslider--slide .slides {
  max-height: 90vh;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.flexslider li {
  margin: 0;
  max-width: 100%; }

.flexslider .slides__slide {
  display: none;
  /* Hide the slides before the JS is loaded. Avoids image jumping */
  margin: 0;
  position: relative;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden; }

.flexslider--adapt .slides__slide {
  height: 100%;
  overflow: hidden; }

.flexslider--adapt.flexslider--fade .slides__slide {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.slide-hide,
.slide-hide > img {
  height: 1px; }

.slide-hide .slides__image,
.slide-hide .placeholder-svg {
  display: none; }

.slides__image {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  display: block;
  object-fit: cover; }

.flexslider--adapt .slides__image,
.flexslider--adapt .placeholder-svg {
  height: 100%;
  overflow: hidden; }

.slides {
  *zoom: 1; }
  .slides:after {
    content: "";
    display: table;
    clear: both; }

html[xmlns] .slides {
  display: block; }

* html .slides {
  height: 1%; }

/*================ No JS Fallback ================*/
.no-js .slides > li:first-child {
  display: block; }

.flexslider {
  position: relative;
  zoom: 1; }

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }

.loading .flex-viewport {
  max-height: 300px; }

.flexslider .slides {
  zoom: 1; }

.carousel li {
  margin-right: 5px; }

/*================ Controls ================*/
.slider__controls-container {
  position: relative; }

.slider__controls-button {
  width: 44px;
  height: 44px;
  position: absolute;
  margin: 3px 0;
  border-style: none;
  background: transparent; }
  .slider__controls-button .icon {
    font-size: 0.7rem;
    color: black;
    opacity: 1;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -ms-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out; }
  .slider__controls-button:hover .icon {
    opacity: 0.5; }

.slider__controls-button--pause {
  right: -15px; }
  .slider__controls-button--pause .icon {
    height: 0.5rem;
    font-size: 0.5rem; }

.icon-pause {
  display: block; }
  .is-paused .icon-pause {
    display: none; }

.icon-play {
  display: none; }
  .is-paused .icon-play {
    display: block; }

/*================ Direction Nav ================*/
.slider-navigation {
  position: relative;
  margin: 0;
  z-index: 2;
  list-style: none; }
  .slider-navigation li {
    margin: 0; }

/*================ Control Nav ================*/
.flex-control-nav {
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 10px 0;
  list-style: none;
  z-index: 3; }
  .flex-control-nav li {
    margin: 0 4px;
    display: inline-block;
    zoom: 1;
    vertical-align: middle; }

.flex-control-paging li a {
  width: 12px;
  height: 12px;
  display: block;
  background-color: black;
  cursor: pointer;
  text-indent: -9999px;
  border-radius: 20px;
  border: 2px solid #fff;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }
  .flex-control-paging li a:hover {
    opacity: 0.5;
    background-color: black; }
  .flex-control-paging li a.flex-active {
    background-color: #fff;
    border-color: black;
    cursor: default; }

.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden; }

.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0; }

.flex-control-thumbs img {
  width: 100%;
  display: block;
  opacity: .7;
  cursor: pointer; }

.flex-control-thumbs img:hover {
  opacity: 1; }

.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default; }

/*================ Custom Flexslider Styles ================*/
.flexslider .slides {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.slides__slide::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0; }
  @media screen and (max-width: 768px) {
    .slides__slide::before {
      content: none; } }

/*================ Slide text ================*/
.slideshow__mobile-text-container {
  display: block; }
  @media screen and (min-width: 769px) {
    .slideshow__mobile-text-container {
      display: none; } }

.slides__text-content {
  width: 80%;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1; }
  .slide-hide .slides__text-content {
    display: none; }
  .no-js .slides__text-content::after {
    opacity: 0;
    visibility: hidden;
    content: none; }

.slides__text-content--mobile {
  display: none;
  position: relative;
  transform: none;
  margin-top: 22px;
  padding-bottom: 1rem; }

.no-js .slides__text-content--mobile:first-of-type {
  display: block; }

.slides-text-content-wrapper-left {
  width: 50%; }

.slides-text-content-wrapper-right {
  width: 50%;
  margin-left: auto; }

.slides__title {
  display: none;
  margin-bottom: 0; }
  @media screen and (min-width: 769px) {
    .slides__title {
      display: block; } }

.slides__title--small {
  font-size: 1.625em; }

.slides__title--large {
  font-size: 2.125em; }

.slides__title--mobile {
  display: block; }
  @media screen and (min-width: 769px) {
    .slides__title--mobile {
      display: none; } }

.slides__btn {
  display: none;
  border: none; }
  @media screen and (min-width: 769px) {
    .slides__btn {
      display: block; } }

.slides__title + .slides__btn {
  margin-top: 45px; }

.slides__btn {
  max-width: 100%;
  display: none;
  word-wrap: break-word;
  min-height: 3.125rem;
  line-height: 2.2; }
  @media screen and (min-width: 769px) {
    .slides__btn {
      display: inline-block; } }

.slides__btn--mobile {
  display: inline-block; }
  @media screen and (min-width: 769px) {
    .slides__btn--mobile {
      display: none; } }

.slide-hide .slides__btn {
  display: none; }

/*================ Social share buttons ================*/
.social-sharing {
  font-family: "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  /*================ Large Buttons ================*/ }
  .social-sharing * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .social-sharing a {
    display: inline-block;
    color: #fff;
    border-radius: 2px;
    margin: 5px 10px 5px 0;
    height: 22px;
    line-height: 22px;
    text-decoration: none;
    font-weight: 400; }
    .social-sharing a:hover {
      color: #fff; }
  .social-sharing span {
    display: inline-block;
    vertical-align: top;
    height: 22px;
    line-height: 22px;
    font-size: 12px; }
  .social-sharing .icon {
    padding: 0 5px 0 10px; }
    .social-sharing .icon:before {
      line-height: 22px; }
  .social-sharing.is-large a {
    height: 44px;
    line-height: 44px; }
    .social-sharing.is-large a span {
      height: 44px;
      line-height: 44px;
      font-size: 18px; }
    .social-sharing.is-large a .icon {
      padding: 0 10px 0 18px; }
      .social-sharing.is-large a .icon:before {
        line-height: 44px; }

.share-title {
  font-weight: 700;
  font-size: 12px;
  padding-right: 10px; }
  .is-large .share-title {
    padding-right: 16px; }

.share-facebook {
  background-color: #3b5998; }
  .share-facebook:hover {
    background-color: #2d4373; }

.share-twitter {
  background-color: #00aced; }
  .share-twitter:hover {
    background-color: #0087ba; }

.share-pinterest {
  background-color: #cb2027; }
  .share-pinterest:hover {
    background-color: #9f191f; }

/*================ Clean Buttons ================*/
.social-sharing.is-clean a {
  background-color: #fff;
  border: 1px solid #ececec;
  color: #333;
  height: 30px;
  line-height: 30px; }
  .social-sharing.is-clean a span {
    height: 30px;
    line-height: 30px;
    font-size: 13px; }
  .social-sharing.is-clean a:hover {
    background-color: #ececec; }
  .social-sharing.is-clean a .share-title {
    font-weight: 400; }
.social-sharing.is-clean .icon-facebook {
  color: #3b5998; }
.social-sharing.is-clean .icon-twitter {
  color: #00aced; }
.social-sharing.is-clean .icon-pinterest {
  color: #cb2027; }

.order-table {
  border: 1px solid #faf9f7; }
  .order-table a {
    color: #1b1e2f; }
  .order-table th, .order-table td {
    border: 0; }
  .order-table tbody th,
  .order-table tfoot th {
    font-weight: normal;
    text-transform: none;
    letter-spacing: 0; }
  .order-table tbody tr + tr {
    border-top: 1px solid #faf9f7; }
  .order-table thead {
    border-bottom: 1px solid #1b1e2f; }
  .order-table tfoot {
    border-top: 1px solid #1b1e2f; }
    .order-table tfoot tr:first-child th, .order-table tfoot tr:first-child td {
      padding-top: 1.25em; }
    .order-table tfoot tr:nth-last-child(2) th, .order-table tfoot tr:nth-last-child(2) td {
      padding-bottom: 1.25em; }
    .order-table tfoot tr:last-child th, .order-table tfoot tr:last-child td {
      border-top: 1px solid #1b1e2f;
      font-weight: 700;
      padding-top: 1.25em;
      padding-bottom: 1.25em; }
  @media screen and (min-width: 768px) {
    .order-table tbody tr:first-child th, .order-table tbody tr:first-child td {
      padding-top: 1.25em; }
    .order-table tbody tr:last-child th, .order-table tbody tr:last-child td {
      padding-bottom: 1.25em; }
      .order-table tbody tr:last-child th .order-discount--list, .order-table tbody tr:last-child td .order-discount--list {
        margin-bottom: 0; }
    .order-table tfoot tr td, .order-table tfoot tr th {
      vertical-align: bottom; } }
  @media screen and (max-width: 767px) {
    .order-table {
      border: 0; }
      .order-table thead {
        display: none; }
      .order-table th,
      .order-table td {
        float: left;
        clear: left;
        width: 100%;
        text-align: right;
        padding: 0.5rem 0;
        border: 0;
        margin: 0; }
      .order-table th::before,
      .order-table td::before {
        content: attr(data-label);
        float: left;
        text-align: left;
        padding-right: 2em;
        max-width: 80%; }
      .order-table tbody tr th:first-child {
        padding-top: 1.25em; }
      .order-table tbody tr td:last-child {
        padding-bottom: 1.25em; }
      .order-table tbody th::before,
      .order-table tbody td::before {
        font-weight: 700; } }

@media screen and (max-width: 767px) {
  .order-table__product {
    display: flex;
    justify-content: space-between; } }

.order-discount {
  text-transform: uppercase;
  color: black;
  display: block;
  line-height: 1.2em; }
  .order-discount .icon-saletag {
    margin-right: 0.8em;
    font-size: 0.9em; }

.order-discount--title {
  word-break: break-word;
  padding-right: 1em; }

.order-discount--cart {
  font-size: 0.85em; }

.order-discount--list {
  margin: 0.8em 0 0.6em 1.3em;
  list-style: none;
  padding: 0; }

.order-discount--price {
  margin-bottom: 0; }

.order-discount__item {
  text-indent: -1.5em; }
  .order-discount__item + .order-discount__item {
    margin-top: 0.6em; }

@media screen and (max-width: 767px) {
  .order-discount-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%; } }

.order-discount-cart-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 7.5px; }

.order-discount__line {
  vertical-align: bottom; }

/*================ View-specific styles ================*/
/*============= Templates | Password page =============*/
.template-password {
  height: 100vh;
  text-align: center; }

.password-page__wrapper {
  display: table;
  height: 100%;
  width: 100%;
  background-image: url("//cdn.shopify.com/s/files/1/0332/8927/3389/t/23/assets/password-page-background.jpg?v=129873446965807520001587537337");
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff; }
  .password-page__wrapper a {
    color: inherit; }
  .password-page__wrapper hr {
    padding: 15px 0;
    margin: 0 auto;
    max-width: 60px;
    border-color: inherit; }
  .password-page__wrapper .social-sharing a {
    color: #fff; }
  .password-page__wrapper .social-sharing.is-clean a {
    color: #333;
    background: #fff; }
    .password-page__wrapper .social-sharing.is-clean a:hover {
      background: #ececec; }

.password-header-section {
  display: table-row; }

.password-page__header {
  display: table-cell;
  height: 1px; }

.password-page__header__inner {
  padding: 15px 30px; }

.password-page__logo {
  margin-top: 90px;
  color: inherit; }
  .password-page__logo .logo {
    max-width: 100%; }

.password-page__main {
  display: table-row;
  width: 100%;
  height: 100%;
  margin: 0 auto; }

.password-page__main__inner {
  display: table-cell;
  vertical-align: middle;
  padding: 15px 30px; }

.password-page__hero {
  font-family: "Crimson Text", serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2.625em;
  line-height: 1.25;
  text-transform: none;
  letter-spacing: 0;
  text-rendering: optimizeLegibility; }
  @media screen and (min-width: 481px) {
    .password-page__hero {
      font-size: 3.75em; } }
  @media screen and (min-width: 769px) {
    .password-page__hero {
      font-size: 4em; } }

.password-page__message {
  font-style: oblique;
  font-size: 120%; }
  .password-page__message img {
    max-width: 100%; }

.password-page__message,
.password-page__login-form,
.password-page__signup-form {
  max-width: 500px;
  margin: 0 auto; }

.password-page__message,
.password-page__login-form {
  text-align: center;
  padding: 30px; }

@media screen and (min-width: 480px) {
  .password-page__login-form,
  .password-page__signup-form {
    padding: 0 30px; } }
.password-page__login-form .input-group,
.password-page__signup-form .input-group {
  width: 100%; }
.password-page__login-form .errors ul,
.password-page__signup-form .errors ul {
  list-style-type: none;
  margin-left: 0; }

.password-page__signup-heading,
.password-page__signup-subheading {
  margin-bottom: 25px; }
  .password-page__signup-heading a,
  .password-page__signup-subheading a {
    color: black; }
    .password-page__signup-heading a:hover,
    .password-page__signup-subheading a:hover {
      color: #1a1a1a; }

input[type="submit"].password-page__login-form__submit,
input[type="submit"].password-page__signup-form__submit {
  font-size: 0.9em; }

.password-page__social-sharing {
  margin-top: 30px; }

.password-login,
.admin-login {
  margin-top: 15px; }
  .password-login a:hover,
  .admin-login a:hover {
    color: inherit; }

.password-login {
  font-family: "Crimson Text", serif;
  font-weight: 700;
  font-style: normal;
  font-size: 0.875em;
  line-height: 14px; }

.lock-icon-svg {
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: baseline; }
  .lock-icon-svg path {
    fill: currentColor; }

.admin-login {
  font-size: 95%; }

.password-page__footer {
  display: table-row;
  height: 1px;
  color: inherit; }

.password-page__footer_inner {
  display: table-cell;
  vertical-align: bottom;
  padding: 30px;
  line-height: 24px;
  font-size: 95%; }

.shopify-link {
  color: inherit; }
  .shopify-link:hover {
    color: inherit; }

.shopify-logo-svg {
  width: 82.28571px;
  height: 24px;
  display: inline-block;
  line-height: 0;
  vertical-align: top; }
  .shopify-logo-svg path {
    fill: currentColor; }

/* =========
   Hiding the word 'Shopify' but not from screen readers.
   ========= */
.shopify-name {
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px; }

.search__image-wrapper {
  width: 100%;
  margin: 0 auto; }
  .search__image-wrapper.supports-js {
    position: relative; }

.search__image {
  display: block;
  margin: 0 auto; }
  .search__image.lazyload {
    opacity: 0; }
  .supports-js .search__image {
    position: absolute;
    top: 0;
    width: 100%; }

/*================ Templates | Cart Page ================*/
.cart__row {
  position: relative;
  padding-top: 30px; }
  .cart__row + .cart__row {
    margin-top: 30px;
    border-top: 1px solid #faf9f7; }
  .cart__row:first-child {
    margin-top: 0; }
  .cart__row:first-child {
    padding-top: 0;
    border-top: 0 none; }
  .cart__row .js-qty {
    margin: 0; }

.cart__row--table-large .grid__item {
  word-wrap: break-word; }

@media screen and (min-width: 769px) {
  .cart__row--table-large {
    display: table;
    table-layout: fixed;
    width: 100%; }
    .cart__row--table-large .grid__item {
      display: table-cell;
      vertical-align: middle; } }
.cart__image-wrapper {
  width: 100%;
  margin: 0 auto; }
  .cart__image-wrapper.supports-js {
    position: relative; }

.cart__image {
  display: block;
  margin: 0 auto; }
  .supports-js .cart__image {
    position: absolute;
    top: 0;
    width: 100%; }
  .cart__image.lazyload {
    opacity: 0; }

.cart__product-title {
  display: inline-block;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 0; }

.cart__subtotal {
  margin-bottom: 5px; }

.cart__subtotal-price,
.cart-additional-savings__price {
  margin: 0 0 0 10px;
  display: inline; }

.cart__policies {
  margin-bottom: 30px;
  font-size: 0.9375em; }

.cart__mini-labels {
  display: block;
  margin: 10px 0;
  font-size: 0.75em; }
  @media screen and (min-width: 769px) {
    .cart__mini-labels {
      display: none; } }

input.cart__quantity-selector {
  width: 50%;
  margin: 0 auto; }
  @media screen and (min-width: 769px) {
    input.cart__quantity-selector {
      float: right; } }

.cart__remove {
  display: block; }

.cart__note-add.is-hidden {
  display: none; }

.cart__note {
  display: none; }
  .cart__note.is-active {
    display: block; }

.cart .btn {
  margin-bottom: 4px; }

.cart__additional_checkout {
  margin: 15px 0 0; }
  .cart__additional_checkout input {
    padding: 0; }
  .cart__additional_checkout > *:not(script) {
    padding: 5px 0 0 5px;
    vertical-align: top;
    line-height: 1; }
    .cart__additional_checkout > *:not(script):first-child, .cart__additional_checkout > *:not(script):empty {
      padding-left: 0px; }

.cart--no-cookies .cart__continue-btn {
  display: none; }

.cart--no-cookies .cart--empty-message {
  display: none; }

.cart--cookie-message {
  display: none; }
  .cart--no-cookies .cart--cookie-message {
    display: block; }

[data-shopify-buttoncontainer] {
  justify-content: flex-end; }
